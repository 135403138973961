import styled from 'styled-components'

export const Container = styled.div`
    color: ${({ theme }) => theme.colors.bundle.text};
    width: 100%;
    background-color: ${({ theme }) => theme.colors.bundle.background};
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    border: 1px solid ${({ theme }) => theme.colors.bundle.border};
    border-radius: 6px;
    padding: 8px 12px;
`

export const ListItem = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`
