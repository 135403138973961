import styled from 'styled-components'

export const FirstRow = styled.div<{ $isTwoRows?: boolean }>`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: ${({ $isTwoRows }) => ($isTwoRows ? '0 0 8px' : '0')};
    width: 100%;
`
export const SecondRow = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 0;
    gap: 4px;
`
