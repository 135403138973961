import React, { FC } from 'react'
import { useTheme } from 'styled-components'
import { Text } from '@/components/typography/Text'
import { ImageContainer, StyledImage } from './styles'

interface ImageFieldProps {
  comparisonContent?: string
  comparisonKey?: string
  label?: string
  content: string
  $key: string
}

export const ImageField: FC<ImageFieldProps> = ({ label, content, $key, comparisonContent, comparisonKey }) => {
  const theme = useTheme()

  return (
    <>
      {label && (
        <Text size='sm' $color={theme.colors.bundle.label}>
          {label}
        </Text>
      )}
      <ImageContainer $withLabel={!!label}>
        <StyledImage src={content} $key={$key} alt={$key} />
        {comparisonContent && comparisonKey && (
          <StyledImage src={comparisonContent} alt={comparisonKey} $key={comparisonKey} />
        )}
      </ImageContainer>
    </>
  )
}
