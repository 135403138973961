/* eslint-disable max-len */
import * as React from 'react'

function Suspected(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width='22' height='20' viewBox='0 0 22 20' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M2.90633 20.0027C2.31517 20.0027 1.79925 19.871 1.35857 19.6077C0.923261 19.3497 0.587375 19.0004 0.350911 18.5597C0.114448 18.119 -0.00378418 17.6354 -0.00378418 17.1087C-0.00378418 16.8561 0.0284609 16.6008 0.092951 16.3429C0.162815 16.0849 0.270299 15.835 0.415402 15.5932L8.50085 1.45371C8.77493 0.970037 9.135 0.60728 9.58106 0.365442C10.0325 0.123605 10.5054 0.00268555 10.9998 0.00268555C11.4835 0.00268555 11.9484 0.123605 12.3944 0.365442C12.8459 0.60728 13.2113 0.970037 13.4908 1.45371L21.5762 15.5932C21.716 15.835 21.8207 16.0849 21.8906 16.3429C21.9605 16.6008 21.9954 16.8561 21.9954 17.1087C21.9954 17.6354 21.8745 18.119 21.6327 18.5597C21.3962 19.0004 21.0603 19.3497 20.625 19.6077C20.1897 19.871 19.6738 20.0027 19.0772 20.0027H2.90633ZM10.9998 12.8362C11.5964 12.8362 11.9081 12.5218 11.9349 11.8931L12.0801 6.82252C12.0908 6.50544 11.9914 6.24748 11.7818 6.04863C11.5776 5.84979 11.3142 5.75037 10.9918 5.75037C10.6586 5.75037 10.3899 5.84979 10.1857 6.04863C9.98681 6.24211 9.89008 6.49738 9.89545 6.81446L10.0406 11.9011C10.062 12.5245 10.3818 12.8362 10.9998 12.8362ZM10.9998 16.3832C11.3438 16.3832 11.6367 16.273 11.8785 16.0527C12.1257 15.8377 12.2493 15.5636 12.2493 15.2304C12.2493 14.8918 12.1257 14.6124 11.8785 14.392C11.6367 14.1717 11.3438 14.0615 10.9998 14.0615C10.6505 14.0615 10.3523 14.1744 10.105 14.4001C9.8632 14.6204 9.74229 14.8972 9.74229 15.2304C9.74229 15.5636 9.8632 15.8377 10.105 16.0527C10.3523 16.273 10.6505 16.3832 10.9998 16.3832Z'
        fill='#F9AE1A'
      />
    </svg>
  )
}

export default React.memo(Suspected)
