import React, { useCallback, useMemo } from 'react'

import Button from '@/components/controls/Button'
import { Text } from '@/components/typography/Text'
import Close from '@/components/icons/cardHeader/Close'
import Edit from '@/components/icons/cardHeader/Edit'
import Table from '@/components/icons/menu/Table'
import { useCardsHelper } from '@/hooks/useCardsHelper'

import { PageConfig } from '@/redux/actions/common/ui'

import { StyledCard } from './styles'

type Props = { title: React.ReactNode; page: PageConfig; openExpanded?: boolean }
const CollapsedCard: React.FC<Props> = ({ title, page, openExpanded }) => {
  const { onOpenCard, onClose } = useCardsHelper()
  const { path, type } = page

  const hasCloseButton = useMemo(() => type === 'form' || type === 'index', [type])

  const handleOpenCard = useCallback(() => {
    onOpenCard(path, type, openExpanded)
  }, [path, type, onOpenCard])
  const handleClose = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault()
      event.stopPropagation()
      onClose(path)
    },
    [path, onClose]
  )

  return (
    <StyledCard offsets={hasCloseButton} onClick={handleOpenCard}>
      {hasCloseButton && (
        <Button icon='icon' size='sm' styleType='tertiary' variant='gray' onClick={handleClose}>
          <Close />
        </Button>
      )}
      {type === 'form' && <Edit />}
      {(type === 'table' || type === 'index') && <Table />}
      <Text size='sm'>{title}</Text>
    </StyledCard>
  )
}

export default CollapsedCard
