import React from 'react'
import Approve from '@/components/icons/cardHeader/Approve'
import MissingInfo from '@/components/icons/cardHeader/MissingInfo'
import Reject from '@/components/icons/cardHeader/Reject'

export const getHeaderActionIcons = (iconName?: string) => {
  switch (iconName) {
    case 'ban':
      return <Reject />
    case 'info-circle':
      return <MissingInfo />
    case 'check-circle':
      return <Approve />
    default:
      return null
  }
}
