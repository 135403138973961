import styled from 'styled-components'

import { CARDS_GAP } from '@/hooks/useCardsHelper'

export const PageLayout = styled.div`
    display: flex;
    min-height: calc(100vh);
    background: ${({ theme }) => theme.colors.gray['100']};
`

export const PageContent = styled.div<{ $isMenuHidden?: boolean }>`
    display: flex;
    gap: ${CARDS_GAP}px;
    padding: 8px;
    width: ${({ $isMenuHidden }) => ($isMenuHidden ? '100vw' : 'calc(100vw - 285px)')};
    height: calc(100vh);
    overflow-y: auto;
    background: ${({ theme }) => theme.colors.gray['100']};
`
