import React, { FC } from 'react'
import { PageConfig } from '@/redux/actions/common/ui'

import Button from '@/components/controls/Button'
import Dropdown from '@/components/controls/Dropdown'
import Tooltip from '@/components/blocks/Tooltip'
import MoreDots from '@/components/icons/cardHeader/MoreDots'

import InlineActions from '../InlineActions'
import FormDropdownMenu from '../../../DropdownMenu'

interface ActionsBlockProps {
  disableActions: boolean
  page: PageConfig
  showActions: boolean
  showDelete?: boolean
  showAdditionalActions?: boolean
}

const ActionsBlock: FC<ActionsBlockProps> = ({
  disableActions,
  page,
  showActions,
  showDelete,
  showAdditionalActions
}) => {
  return showActions ? (
    <>
      <InlineActions disableActions={disableActions} page={page} />
      <Dropdown
        control={
          <Tooltip position='bottom' description='Menu'>
            <Button type='button' icon='icon' size='sm' styleType='tertiary' variant='gray'>
              <MoreDots />
            </Button>
          </Tooltip>
        }
        popup={(onCloseMenu) => (
          <FormDropdownMenu
            disableActions={disableActions}
            page={page}
            onCloseMenu={onCloseMenu}
            showDelete={showDelete ?? true}
            showAdditionalActions={showAdditionalActions ?? true}
          />
        )}
      />
    </>
  ) : null
}

export default ActionsBlock
