import styled from 'styled-components'

interface BundleSectionWrapperProps {
  layout: 'horizontal' | 'vertical'
}

export const BundleContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  color: ${({ theme }) => theme.colors.bundle.text};
  background-color: ${({ theme }) => theme.colors.bundle.background};
  border-top: 1px solid ${({ theme }) => theme.colors.bundle.border};
`

export const BundleSectionWrapper = styled.div<BundleSectionWrapperProps>`
  width: ${({ layout }) => `${layout === 'horizontal' ? '75%' : '25%'}`};
  overflow: hidden;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.bundle.background};
  border-right: 1px solid ${({ theme }) => theme.colors.bundle.border};
  border-left: 1px solid ${({ theme }) => theme.colors.bundle.border};
  padding: 24px;
  gap: 16px;
`

export const BundleSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  border-radius: 16px;
  background-color: ${({ theme }) => theme.colors.base.white};
  border: 1px solid ${({ theme }) => theme.colors.bundle.border};
  padding: 16px 24px 24px;
`

export const BundleSectionGroup = styled.div`
  display: flex;
  flex-direction: row;
  flexwrap: nowrap;
  border-radius: 16px;
  background-color: ${({ theme }) => theme.colors.base.white};
  border: 1px solid ${({ theme }) => theme.colors.bundle.border};
  padding: 0;
  & > div {
    border: none;
    width: 33%;
  }
  & > div:nth-child(2) {
    border-right: 1px solid ${({ theme }) => theme.colors.bundle.border};
    border-left: 1px solid ${({ theme }) => theme.colors.bundle.border};
    border-radius: 0;
  }
`

export const BundleFieldsWrapper = styled.div`
  width: 100%;
  display: flex;
  gap: 6px;
  flex-direction: column;
`
