import styled from 'styled-components'
import { getImageWidthByKey } from '../../utils'

export const StyledImage = styled.img<{ $key?: string }>`
    width: ${({ $key }) => getImageWidthByKey($key)};
    border-radius: 8px;
    object-fit: cover;
`

export const ImageContainer = styled.div<{ $withLabel: boolean }>`
    display: flex;
    padding: 0;
    margin: 0;
    flex-direction: row;
    gap: 8px;
    max-height: ${({ $withLabel }) => ($withLabel ? '120px' : '100%')};
`
