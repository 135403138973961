import styled from 'styled-components'

import { TextSize, TextWeight, TEXT_SIZE_STYLES, TEXT_WEIGHT_STYLES } from '@/constants/styles/typography/text'

export type TextProps = {
    size?: TextSize
    weight?: TextWeight
    $color?: string
    children?: React.ReactNode
}

export const Text = styled.div.attrs(({ size, weight, $color }: TextProps) => ({
    className: ['text', size, weight].join(' ')
}))<TextProps>`
    ${({ size }) => TEXT_SIZE_STYLES[size!]};
    ${({ weight }) => TEXT_WEIGHT_STYLES[weight!]};
    ${({ $color }) => $color && `color: ${$color};`};
`

Text.defaultProps = {
    size: 'md',
    weight: 'medium'
}
