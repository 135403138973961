import { ActionTarget, ModelType, ModelTypeUIActions } from '@/client/schema'
import { schema } from '@/helpers/schema'

type ActionFilter = Record<string, any[]>
type ActionConfig = {
    action: string
    filter?: ActionFilter
    group?: string
    type?: string
    icon?: string
    rootMenu?: boolean
}
type ModelActions = Record<string, ActionConfig[]>
type ActionVisibility = Record<string, ModelTypeUIActions>

export const ACTIONS_BY_MODEL_KEY: ModelActions = Object.keys(schema.actions || {}).reduce((accumulator, name) => {
    const action = schema.actions![name] as ActionTarget[]
    if (action.length > 0) {
        const actionName = name!.replaceAll(' ', '_')
        const localAccumulator = { ...accumulator } as ModelActions
        action.forEach((config) => {
            const modelKey = config.__ref.__key
            localAccumulator[modelKey] = [
                ...(localAccumulator[modelKey] || []),
                {
                    action: actionName,
                    filter: config.filter,
                    type: config.type,
                    group: config.group,
                    icon: config.icon,
                    rootMenu: config.rootMenu
                } as ActionConfig
            ]
        })
        return localAccumulator
    }
    return accumulator
}, {})

const getActionsByModelKey = (modelKey: string) => {
    const model = schema.models[modelKey] as ModelType
    const defaultActions: ModelTypeUIActions = {
        new: true,
        delete: true
    }
    return {
        ...defaultActions,
        ...(model.ui?.actions || {})
    }
}

export const ACTIONS_VISIBILITY_BY_MODEL_KEY: ActionVisibility = Object.keys(schema.models).reduce(
    (accumulator, modelKey) => {
        const model = schema.models[modelKey]
        if (model.type === 'Type' || model.type === 'ObjectType') {
            return {
                ...accumulator,
                [modelKey]: getActionsByModelKey(modelKey)
            }
        }
        return accumulator
    },
    {}
)
