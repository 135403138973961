import styled from 'styled-components'

export const FileInputWrapper = styled.div`
    background-color: ${({ theme }) => theme.colors.bundle.background};
    width: 100%;
    border: 1px solid ${({ theme }) => theme.colors.bundle.border};
    border-radius: 6px;
    padding: 5px 8px 5px 6px;
`

export const FileInputComponent = styled.div`
    background-color: ${({ theme }) => theme.colors.base.white};
    padding: 0 4px;
    border-radius: 6px;
    width: 100%;
    border: 1px solid ${({ theme }) => theme.colors.bundle.border};
    position: relative;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    display: flex;
    align-items: center;
    justify-content: space-between;
`

export const PdfIconContainer = styled.div`
    display: flex;
    align-items: center;
`

export const OpenIconContainer = styled.div`
    display: flex;
    align-items: center;
    cursor: pointer;
`

export const TextContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 6px;
`
