import React, { FC } from 'react'
import { Field } from '../../types'
import { Container, ListItem } from './styles'
import { StatusIcon } from '../StatusIcon'

interface ListFieldProps {
  values: Field[]
}

export const ListField: FC<ListFieldProps> = ({ values }) => {
  return (
    <Container>
      {values.map((value) => (
        <ListItem key={value.key}>
          {value.label}
          {value.check?.status && <StatusIcon status={value.check.status} />}
        </ListItem>
      ))}
    </Container>
  )
}
