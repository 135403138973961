import styled from 'styled-components'

export const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
    gap: 4px;
`

export const ActionItem = styled.div`
    padding: 5px 10px;
    display: flex;
    gap: 4px;
    align-ttems: center;
    cursor: pointer;
`
