/* eslint-disable max-len */
import * as React from 'react'

function Open(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width='22' height='16' viewBox='0 0 22 16' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M10.6 3.60002C10.048 3.60002 9.59999 4.04802 9.59999 4.60002V11.4C9.59999 11.952 10.048 12.4 10.6 12.4H17.4C17.952 12.4 18.4 11.952 18.4 11.4V9.40002C18.4 9.24089 18.4632 9.08828 18.5757 8.97576C18.6883 8.86324 18.8409 8.80002 19 8.80002C19.1591 8.80002 19.3117 8.86324 19.4243 8.97576C19.5368 9.08828 19.6 9.24089 19.6 9.40002V11.4C19.6 11.9835 19.3682 12.5431 18.9556 12.9557C18.543 13.3682 17.9835 13.6 17.4 13.6H10.6C10.0165 13.6 9.45694 13.3682 9.04436 12.9557C8.63178 12.5431 8.39999 11.9835 8.39999 11.4V4.60002C8.39999 4.01655 8.63178 3.45697 9.04436 3.04439C9.45694 2.63181 10.0165 2.40002 10.6 2.40002H12.6C12.7591 2.40002 12.9117 2.46324 13.0243 2.57576C13.1368 2.68828 13.2 2.84089 13.2 3.00002C13.2 3.15915 13.1368 3.31177 13.0243 3.42429C12.9117 3.53681 12.7591 3.60002 12.6 3.60002H10.6Z'
        fill='#51616C'
      />
      <path
        d='M19 2.40002H15.6C15.4409 2.40002 15.2883 2.46324 15.1757 2.57576C15.0632 2.68828 15 2.84089 15 3.00002C15 3.15915 15.0632 3.31177 15.1757 3.42429C15.2883 3.53681 15.4409 3.60002 15.6 3.60002H17.552L12.576 8.57603C12.517 8.63095 12.4698 8.6972 12.437 8.77079C12.4042 8.84439 12.3865 8.92384 12.3851 9.00441C12.3837 9.08497 12.3985 9.16499 12.4287 9.2397C12.4589 9.31441 12.5038 9.38228 12.5608 9.43926C12.6177 9.49623 12.6856 9.54115 12.7603 9.57132C12.835 9.6015 12.915 9.61632 12.9956 9.6149C13.0762 9.61348 13.1556 9.59584 13.2292 9.56305C13.3028 9.53026 13.3691 9.48297 13.424 9.42402L18.4 4.44802V6.40002C18.4 6.55915 18.4632 6.71177 18.5757 6.82429C18.6883 6.93681 18.8409 7.00002 19 7.00002C19.1591 7.00002 19.3117 6.93681 19.4243 6.82429C19.5368 6.71177 19.6 6.55915 19.6 6.40002V3.00002C19.6 2.84089 19.5368 2.68828 19.4243 2.57576C19.3117 2.46324 19.1591 2.40002 19 2.40002Z'
        fill='#51616C'
      />
    </svg>
  )
}

export default React.memo(Open)
