/* eslint-disable max-len */
import * as React from 'react'

function Clear(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M4.4443 1.6853C6.08879 0.58649 8.02219 0 10 0C11.3132 0 12.6136 0.258658 13.8268 0.761205C15.0401 1.26375 16.1425 2.00035 17.0711 2.92893C17.9997 3.85752 18.7363 4.95991 19.2388 6.17317C19.7413 7.38642 20 8.68678 20 10C20 11.9778 19.4135 13.9112 18.3147 15.5557C17.2159 17.2002 15.6541 18.4819 13.8268 19.2388C11.9996 19.9957 9.98891 20.1937 8.0491 19.8079C6.10929 19.422 4.32746 18.4696 2.92894 17.0711C1.53041 15.6725 0.578004 13.8907 0.192152 11.9509C-0.193701 10.0111 0.00433284 8.00043 0.761209 6.17317C1.51809 4.3459 2.79981 2.78412 4.4443 1.6853ZM8.42999 11.0901L12.72 6.79006C12.9074 6.60381 13.1608 6.49927 13.425 6.49927C13.6892 6.49927 13.9426 6.60381 14.13 6.79006C14.2237 6.88302 14.2981 6.99362 14.3489 7.11548C14.3997 7.23734 14.4258 7.36805 14.4258 7.50006C14.4258 7.63207 14.3997 7.76278 14.3489 7.88464C14.2981 8.0065 14.2237 8.1171 14.13 8.21006L9.12999 13.2101C8.94373 13.3948 8.69233 13.499 8.42999 13.5001C8.29838 13.5008 8.16792 13.4756 8.04608 13.4258C7.92425 13.3761 7.81343 13.3027 7.71999 13.2101L5.36999 10.8501C5.26531 10.7604 5.18029 10.6501 5.12027 10.526C5.06024 10.402 5.02651 10.2668 5.02119 10.1291C5.01587 9.99139 5.03908 9.85406 5.08935 9.72574C5.13963 9.59741 5.21589 9.48086 5.31334 9.38341C5.41079 9.28595 5.52734 9.2097 5.65567 9.15942C5.78399 9.10915 5.92132 9.08594 6.05904 9.09126C6.19676 9.09658 6.3319 9.13031 6.45596 9.19033C6.58002 9.25036 6.69034 9.33538 6.77999 9.44006L8.42999 11.0901Z'
        fill='#57AD57'
      />
    </svg>
  )
}

export default React.memo(Clear)
