import React from 'react'
import Skipped from '@/components/icons/bundle/skipped'
import Clear from '@/components/icons/bundle/clear'
import Rejected from '@/components/icons/bundle/rejected'
import Suspected from '@/components/icons/bundle/suspected'
import { Status } from './types'

export const getBundleFieldIcon = (status?: Status) => {
  switch (status) {
    case 'PASSED':
      return <Clear />
    case 'FAILED':
      return <Rejected />
    case 'SKIPPED':
      return <Skipped />
    case 'WARNING':
      return <Suspected />
    default:
      return null
  }
}

export const getImageWidthByKey = (key?: string) => {
  switch (key) {
    case 'FRONTSIDE':
    case 'BACKSIDE':
      return '190px'
    case 'SIGNATURE':
      return '256px'
    case 'DOC_FACE':
      return '92px'
    default:
      return '120px'
  }
}
