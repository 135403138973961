import React from 'react'
import { Field, FieldProps } from 'formik'
import objectPath from 'object-path'
import Bundle, { BundleValue, BundleProps } from '@/components/controls/Bundle'

interface BundleFieldProps extends BundleProps {
  name: string
}

const BundleField: React.FC<BundleFieldProps> = ({ ...props }) => (
  <Field {...props}>
    {({ field, form }: FieldProps<BundleValue>) => {
      const error = objectPath.get(form.errors, field.name)

      return <Bundle {...field} {...props} error={error} />
    }}
  </Field>
)

export default BundleField
