import React, { FC } from 'react'
import { Status } from '../../types'
import { getBundleFieldIcon } from '../../utils'
import { Container } from './styles'

interface StatusIconProps {
  status?: Status
}

export const StatusIcon: FC<StatusIconProps> = ({ status }) => {
  return <Container>{getBundleFieldIcon(status)}</Container>
}
