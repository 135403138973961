import { useCallback, useMemo } from 'react'

type Page = { title: string; index?: number; key?: string }
export type PaginationType = {
    pageIndex: number
    nextTokens: (string | null)[]
}
type PaginationHandlers = { onChange: (pageIndex: number) => void }
export type PaginationProps = PaginationType & PaginationHandlers

const SIBLING_COUNT = 2

const getElipsis = (key: string) => ({ title: '...', key }) as Page
const getPagesArray = (start: number, end: number) => {
    const length = end - start + 1
    return Array.from({ length }, (_, idx) => ({ title: `${idx + start}`, index: idx + start - 1 }) as Page)
}

export const insertPage = <T = string | null>(arr: T[], index: number, newItem: T) => {
    const startItems = arr.slice(0, index)
    const endItems = arr.slice(index, arr.length - 1)
    return [...startItems, newItem, ...endItems]
}

const usePagesRange = (currentPageParam: number, totalPagesParam: number, hasLastPage = false): Page[] => {
    const range: Page[] = useMemo(() => {
        const currentPage = Math.max(currentPageParam, 1)
        const totalPages = Math.max(currentPage, totalPagesParam)
        const maxNumOfItems = 5 + 2 * SIBLING_COUNT

        const firstPage = 1
        const firstPageItem = { title: '1', index: 0 } as Page
        const lastPage = hasLastPage ? totalPages - 1 : totalPages
        const lastPageItem = { title: `${lastPage}`, index: lastPage - 1 } as Page
        const endRange = hasLastPage ? [] : [getElipsis('right')]

        if (totalPages < maxNumOfItems) {
            return [...getPagesArray(1, lastPage), ...endRange]
        }

        const leftSiblingPage = Math.max(currentPage - SIBLING_COUNT, firstPage)
        const rightSiblingPage = Math.min(currentPage + SIBLING_COUNT, lastPage)

        const shouldRenderStartEllipsis = leftSiblingPage > 2
        const shouldRenderEndEllipsis = rightSiblingPage < lastPage - 1

        if (shouldRenderStartEllipsis && !shouldRenderEndEllipsis) {
            const rightItemCount = 3 + 2 * SIBLING_COUNT
            const rightRange = getPagesArray(lastPage - rightItemCount + 1, lastPage)
            return [firstPageItem, getElipsis('left'), ...rightRange, ...endRange]
        }
        if (!shouldRenderStartEllipsis && shouldRenderEndEllipsis) {
            const leftItemCount = 3 + 2 * SIBLING_COUNT
            const leftRange = getPagesArray(firstPage, leftItemCount)
            const rightRange = hasLastPage ? [getElipsis('right'), lastPageItem] : endRange
            return [...leftRange, ...rightRange]
        }

        const middleRange = getPagesArray(leftSiblingPage, rightSiblingPage)
        const rightRange = hasLastPage ? [...endRange, lastPageItem] : endRange
        return [firstPageItem, getElipsis('left'), ...middleRange, ...rightRange]
    }, [currentPageParam, totalPagesParam, hasLastPage])

    return range
}

export const usePagination = ({ pageIndex, nextTokens, onChange }: PaginationProps) => {
    const allPages = useMemo(() => nextTokens[0] === null && nextTokens[nextTokens.length - 1] === null, [nextTokens])
    const pages = usePagesRange(pageIndex + 1, nextTokens.length, allPages)
    const prevDisabled = useMemo(() => pageIndex === 0, [pageIndex])
    const nextDisabled = useMemo(
        () => pageIndex === nextTokens.length - 1 || nextTokens[pageIndex + 1] === null,
        [pageIndex, nextTokens]
    )

    const handlePrev = useCallback(() => {
        if (prevDisabled) {
            return
        }
        onChange(pageIndex - 1)
    }, [pageIndex, prevDisabled, onChange])
    const handleNext = useCallback(() => {
        if (nextDisabled) {
            return
        }
        onChange(pageIndex + 1)
    }, [pageIndex, nextDisabled, onChange])
    const handleSelect = useCallback(
        (index: number) => {
            onChange(index)
        },
        [onChange]
    )

    return {
        onePage: nextTokens.length < 2 || nextTokens.every((token) => token === null),
        pages,
        pageIndex,
        prevDisabled,
        nextDisabled,
        onPrev: handlePrev,
        onNext: handleNext,
        onSelect: handleSelect
    }
}
