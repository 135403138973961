import styled from 'styled-components'

export const BaseFieldContainer = styled.div`
    width: 100%;
    display: flex;
    gap: 6px;
    flex-direction: column;
`

export const InputWrapper = styled.div`
    position: relative;
    max-height: 36px;
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    padding: 7.5px 12px;
    background-color: ${({ theme }) => theme.colors.bundle.background};
    border: 1px solid ${({ theme }) => theme.colors.bundle.border};
    border-radius: 6px;
`

export const StyledInput = styled.input`
    box-sizing: border-box;
    max-height: 36px;
    color: ${({ theme }) => theme.colors.bundle.text};
    width: 100%;
    background-color: ${({ theme }) => theme.colors.bundle.background};
    border: none;
    padding: 0;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;

    &:focus {
        outline: none;
        border: none;
    }
`
