import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { arrayMove, List } from 'react-movable'
import { useSelector } from 'react-redux'

import SelectValue from '@/components/blocks/SelectValue'
import Plus from '@/components/icons/cardHeader/Plus'
import Close from '@/components/icons/cardHeader/Close'
import LargeFieldBlock, { LargeFieldBlockProps } from '@/components/blocks/LargeFieldBlock'
import { FieldState, PreviewFields } from '@/helpers/model/form/fields'
import { TITLE_GETTER_BY_MODEL_KEY } from '@/helpers/model/title'
import { WORKFLOW_GETTER_BY_MODEL_KEY } from '@/helpers/model/workflow'
import { useCardsHelper } from '@/hooks/useCardsHelper'
import { Model } from '@/client/schema'
import { PageConfig } from '@/redux/actions/common/ui'
import { selectSubformValue } from '@/redux/selectors/subform'
import { useAppDispatch } from '@/redux/store'
import { deleteSubformUpdatedDataAction, setSubformAction } from '@/redux/actions/subform'
import { addSubformToHash } from '@/helpers/url'
import { getId } from '@/helpers/subforms'

import Button from '../Button'

import { ControlsMultilineRow, ControlsRow, Values } from './styles'

function getNestedField(nameObj: any): string | undefined {
  if (typeof nameObj === 'string') {
    return nameObj
  }

  const { name } = nameObj
  if (typeof name === 'string') {
    return name
  }

  const candidatesKeys = Object.keys(nameObj).filter((key) => !key.startsWith('__') && key !== 'id')

  for (let i = 0; i < candidatesKeys.length; i = +1) {
    const candidate = nameObj[candidatesKeys[i]]
    if (typeof candidate === 'string') {
      return candidate
    }

    if (typeof candidate === 'object') {
      const nested = getNestedField(candidate)
      if (nested) {
        return nested
      }
    }
  }

  return undefined
}

function getName(option: Record<string, any>, typeMap: Record<string, { state: string; key: string; type: string }>) {
  const nameObj = option[typeMap[option.__type]?.key] || '-//-'

  const result = getNestedField(nameObj)

  return result || '-//-'
}

export type ObjectValueType = Record<string, any>

export type ObjectSelectProps = LargeFieldBlockProps & {
  modelKey?: string
  disabled?: boolean
  state?: FieldState
  value?: ObjectValueType[] | ObjectValueType
  previewFields?: PreviewFields
  models: {
    type: string
    model: Model
  }[]
  parentPage: PageConfig
  list?: boolean
  name?: string

  onOpen?: (item: ObjectValueType, expanded: boolean) => void
  onChange?: (values: ObjectValueType[]) => void
}
const ObjectSelect: React.FC<ObjectSelectProps> = ({
  modelKey,
  disabled,
  value,
  previewFields,
  models,
  parentPage,
  list,
  name,

  onChange,

  ...fieldBlockProps
}) => {
  const [showModelSelector, setShowModelSelector] = useState<boolean>()

  const dispatch = useAppDispatch()
  const canAddMore = useMemo(() => list || !value, [value, list])
  const titleConfig = useMemo(() => TITLE_GETTER_BY_MODEL_KEY[modelKey!], [modelKey])

  const visibleValues = useMemo(() => {
    if (list) {
      return Array.isArray(value) ? value.map((v, i) => ({ ...v, id: i })) : []
    }
    return value ? [{ ...value, id: 0 }] : []
  }, [list, value])
  const { activeCards, onOpenSubform, onClose } = useCardsHelper()

  const typeMap = useMemo(() => {
    return models?.reduce(
      (accumulator, v) => {
        if (v.model.__key) {
          return {
            ...accumulator,
            [v.model.__key]: {
              state: WORKFLOW_GETTER_BY_MODEL_KEY[v.model.__key]?.key,
              type: v.type,
              key: TITLE_GETTER_BY_MODEL_KEY[v.model.__key]?.key
            }
          }
        }
        return accumulator
      },
      {} as Record<string, { state: string; key: string; type: string }>
    )
  }, [models])

  const handleOpenSubform = useCallback(
    (modelToOpen?: Model, item?: ObjectValueType) => {
      const modelForSubform = modelToOpen ?? models.find((mdl) => mdl.model.__key === item?.__key)?.model
      const itemToOpen = { __type: modelForSubform?.__key, __id: item?.__id ?? 'new', __field: name, ...item }
      dispatch(
        setSubformAction({
          key: parentPage.path,
          values: itemToOpen
        })
      )

      const openCards = activeCards.filter((ac) => ac.page.path.startsWith(addSubformToHash(parentPage.path, `/`)))
      openCards.forEach((oc) => {
        onClose(oc.page.path)
      })

      onOpenSubform(
        addSubformToHash(parentPage.path, `/${modelForSubform!.__key}/${name!}/${itemToOpen.__id}`),
        false,
        parentPage.path
      )
    },
    [onOpenSubform, parentPage, activeCards, dispatch, models, name, onClose]
  )

  const handleAdd = useCallback(
    (modelToAdd: Model) => {
      handleOpenSubform(modelToAdd)
    },
    [handleOpenSubform]
  )

  const newValue = useSelector(selectSubformValue(parentPage.path))
  useEffect(() => {
    if (newValue && newValue.updatedData && newValue.updated) {
      const { updatedData } = newValue
      if (updatedData.__field === name) {
        const valueToSave = {
          ...updatedData,
          __id: updatedData.__id === 'new' ? getId() : updatedData.__id
        }

        if (list) {
          const values = [...(visibleValues ?? [])]
          const editIndex = values.findIndex((vtc) => vtc.__id === valueToSave.__id)
          if (editIndex === -1) {
            values.push(valueToSave)
          } else {
            values[editIndex as number] = valueToSave
          }
          onChange && onChange(values)
          dispatch(deleteSubformUpdatedDataAction(parentPage.path))
        } else {
          onChange && onChange([valueToSave])
          dispatch(deleteSubformUpdatedDataAction(parentPage.path))
        }
      }
    }
  }, [newValue, name, dispatch, list, onChange, parentPage.path, visibleValues])

  const handleOpen = useCallback(
    (valueToOpen: ObjectValueType) => () => {
      const modelToOpen = models.find((mdl) => mdl.model.__key === valueToOpen.__type)?.model
      handleOpenSubform(modelToOpen ?? models[0].model, valueToOpen)
    },
    [models, handleOpenSubform]
  )

  const handleDelete = useCallback(
    (deleteValue: ObjectValueType) => () => {
      const deletedValue = visibleValues.filter((val) => val !== deleteValue)
      onChange && onChange(deletedValue)
    },
    [visibleValues, onChange]
  )

  const handleSelectModel = useCallback(
    (modelToSelect: Model) => {
      if (modelToSelect) {
        handleAdd(modelToSelect)
      }
      setShowModelSelector(false)
    },
    [handleAdd]
  )

  const handleButtonAdd = useCallback(() => {
    if (canAddMore) {
      if (models.length === 1) {
        handleAdd(models[0].model)
      } else {
        setShowModelSelector(true)
      }
    }
  }, [canAddMore, handleAdd, models])

  const handleButtonCancel = useCallback(() => {
    setShowModelSelector(false)
  }, [])

  const addButton = showModelSelector ? (
    <>
      <ControlsMultilineRow>
        {models.map((mdl) => (
          <Button
            type='button'
            size='sm'
            icon='left'
            styleType='secondary'
            variant='gray'
            onClick={() => handleSelectModel(mdl.model)}
            key={mdl.model.__key}
          >
            <Plus />
            {mdl.type}
          </Button>
        ))}
      </ControlsMultilineRow>
      <ControlsRow>
        <Button type='button' size='sm' icon='left' styleType='link' variant='gray' onClick={handleButtonCancel}>
          <Close />
          Cancel
        </Button>
      </ControlsRow>
    </>
  ) : (
    <ControlsRow>
      <Button
        type='button'
        size='sm'
        icon='left'
        styleType='tertiary'
        variant='gray'
        disabled={disabled}
        onClick={handleButtonAdd}
      >
        <Plus />
        Add
      </Button>
    </ControlsRow>
  )

  return (
    <LargeFieldBlock {...fieldBlockProps}>
      {visibleValues.length > 0 && (
        <List<Record<string, any>>
          lockVertically
          values={visibleValues}
          onChange={({ oldIndex, newIndex }) => onChange && onChange(arrayMove(visibleValues!, oldIndex, newIndex))}
          renderList={({ children, props }) => <Values {...props}>{children}</Values>}
          renderItem={({ value: option, isDragged, props: { ref, ...props } }) => (
            <SelectValue
              key={option.__id}
              ref={ref}
              dndProps={{ ...props, isDragged }}
              titleConfig={titleConfig}
              controlsOnHover
              value={{
                name: getName(option, typeMap),
                state: option[typeMap[option.__type]?.state] ?? typeMap[option.__type]?.type
              }}
              disabled={disabled}
              previewFields={previewFields}
              onOpen={handleOpen(option)}
              onClear={handleDelete(option)}
            />
          )}
        />
      )}
      {canAddMore && addButton}
    </LargeFieldBlock>
  )
}

ObjectSelect.defaultProps = {
  modelKey: undefined,
  value: undefined,
  disabled: false,
  state: undefined,
  previewFields: undefined,
  list: false,
  name: '',

  onOpen: undefined,
  onChange: undefined
}

export default ObjectSelect
