import { LargeFieldBlockProps } from '@/components/blocks/LargeFieldBlock'
import React, { useEffect, useMemo, useState, Fragment } from 'react'
import config from '@/helpers/config'
import { useTheme } from 'styled-components'
import { Text } from '@/components/typography/Text'
import { ListField } from './components/ListField'
import { BundleContainer, BundleFieldsWrapper, BundleSection, BundleSectionGroup, BundleSectionWrapper } from './styles'
import { BaseField } from './components/BaseField'
import { Res } from './types'
import { ImageField } from './components/ImageField'

export type BundleValue = string
export type BundleProps = LargeFieldBlockProps & {
  path: string
  extension: string
  value?: BundleValue
}

const PRE_SIGNED_URL = `${config.REACT_APP_MEDIA_UPLOAD_URL}/api/v1/pre-signed-url`
const DOC_FACE_IMAGE_KEY = 'DOC_FACE'

const Bundle: React.FC<BundleProps> = ({ path, extension, value }) => {
  const theme = useTheme()
  const rawPath = useMemo(() => (path.startsWith('/') ? path.substring(1) : path), [path])
  const key = useMemo(() => (value ? `${rawPath}/${value}.${extension}` : null), [rawPath, extension, value])

  const [data, setData] = useState<Res | null>(null)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)

  useEffect(() => {
    if (!key) {
      return
    }
    const fetchData = async () => {
      try {
        const response = await fetch(`${PRE_SIGNED_URL}?key=${key}`)
        if (!response.ok) {
          throw new Error('Failed to fetch data')
        }
        const result = await response.json()
        const bundleResponse = await fetch(result.preSignedUrl)
        if (!bundleResponse.ok) {
          throw new Error('Failed to fetch data')
        }
        const bundleData = await bundleResponse.text()
        const arrayToken = bundleData?.split('.')
        const tokenPayload = JSON.parse(atob(arrayToken[1]))
        const res = JSON.parse(atob(tokenPayload.res)) as Res
        setData(res)
      } catch (e) {
        setError(e.message)
      } finally {
        setLoading(false)
      }
    }
    fetchData()
  }, [key])

  if (loading) return <div>Loading...</div>
  if (error || !data?.payload) return <div>Not available</div>

  return (
    <BundleContainer>
      <BundleSectionWrapper layout='vertical'>
        <h2>Personal Information</h2>
        {data.payload.face && (
          <BundleSection>
            <h3>Photo</h3>
            <ImageField content={data.payload.face.image} $key='face' />
            <BaseField label='Liveness' value='Passive check' status={data.payload.face.liveness?.status} />
          </BundleSection>
        )}
        {data.payload.bio?.sections?.map((section) => (
          <BundleSection key={section.key}>
            <h3>{section.label}</h3>
            {section.fields.map((field) => (
              <BaseField
                key={field.key}
                label={field.label}
                value={field.content || ''}
                status={field.check?.status}
                type={field.type}
              />
            ))}
          </BundleSection>
        ))}
      </BundleSectionWrapper>
      <BundleSectionWrapper layout='horizontal'>
        {data.payload.docs.map((doc) => (
          <Fragment key={doc.key}>
            <h2>{doc.label}</h2>
            <BundleSectionGroup>
              <BundleSection>
                <h3>Document</h3>
                {doc.fields.map((field) => (
                  <BaseField
                    key={field.key}
                    label={field.label}
                    value={field.content || ''}
                    status={field.check?.status}
                  />
                ))}
              </BundleSection>
              <BundleSection>
                <h3>Images</h3>
                {doc.images.map((image) => {
                  const isDocFaceImage = image.key === DOC_FACE_IMAGE_KEY

                  return (
                    <BundleFieldsWrapper key={image.key}>
                      <ImageField
                        label={image.label}
                        content={image.content}
                        $key={image.key}
                        comparisonKey={isDocFaceImage ? 'face' : undefined}
                        comparisonContent={isDocFaceImage ? data.payload.face.image : undefined}
                      />
                    </BundleFieldsWrapper>
                  )
                })}
              </BundleSection>
              <BundleSection>
                <h3>Analysis</h3>
                {doc.analysis.map((analysis) => (
                  <BundleFieldsWrapper key={analysis.key}>
                    <Text size='sm' $color={theme.colors.bundle.label}>
                      {analysis.label}
                    </Text>
                    <ListField values={analysis.fields} />
                  </BundleFieldsWrapper>
                ))}
              </BundleSection>
            </BundleSectionGroup>
          </Fragment>
        ))}
      </BundleSectionWrapper>
    </BundleContainer>
  )
}

export default Bundle
