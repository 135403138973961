import styled from 'styled-components'

export const SidebarMenuContainer = styled.div<{ $isHidden: boolean }>`
    display: ${({ $isHidden }) => ($isHidden ? 'none' : 'block')};
    min-width: 285px;
    border-right: 1px solid ${({ theme }) => theme.colors.gray['200']};
`

export const MenuBlock = styled.div`
    padding: 8px 0;
    border-bottom: 1px solid ${({ theme }) => theme.colors.gray['200']};

    &:last-child {
        border-bottom: none;
    }
`
