/* eslint-disable max-len */
import * as React from 'react'

function Rejected(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M9.99209 20.0027C8.89058 20.0027 7.83913 19.8325 6.83776 19.492C5.84139 19.1565 4.92513 18.6834 4.08898 18.0725C3.25783 17.4617 2.53684 16.7382 1.926 15.902C1.31516 15.0709 0.83951 14.1571 0.499041 13.1608C0.16358 12.1594 -0.00415039 11.108 -0.00415039 10.0064C-0.00415039 8.89992 0.16358 7.84847 0.499041 6.8521C0.83951 5.85073 1.31516 4.93447 1.926 4.10332C2.53684 3.27218 3.25783 2.55119 4.08898 1.94035C4.92513 1.3245 5.84139 0.848849 6.83776 0.513388C7.83913 0.17292 8.89058 0.00268555 9.99209 0.00268555C11.0986 0.00268555 12.1501 0.17292 13.1464 0.513388C14.1478 0.848849 15.0641 1.3245 15.8952 1.94035C16.7264 2.55119 17.4473 3.27218 18.0582 4.10332C18.674 4.93447 19.1497 5.85073 19.4851 6.8521C19.8256 7.84847 19.9958 8.89992 19.9958 10.0064C19.9958 11.108 19.8256 12.1594 19.4851 13.1608C19.1497 14.1571 18.674 15.0709 18.0582 15.902C17.4473 16.7382 16.7264 17.4617 15.8952 18.0725C15.0641 18.6834 14.1478 19.1565 13.1464 19.492C12.1501 19.8325 11.0986 20.0027 9.99209 20.0027ZM6.83025 14.062C7.0906 14.062 7.31091 13.9719 7.49116 13.7916L9.99209 11.2832L12.493 13.7916C12.6633 13.9719 12.8811 14.062 13.1464 14.062C13.3968 14.062 13.6071 13.9769 13.7773 13.8067C13.9525 13.6314 14.0402 13.4161 14.0402 13.1608C14.0402 12.9104 13.9525 12.7001 13.7773 12.5299L11.2613 10.0215L13.7848 7.49048C13.9601 7.31524 14.0477 7.10495 14.0477 6.85961C14.0477 6.61428 13.9601 6.40649 13.7848 6.23626C13.6146 6.06602 13.4068 5.98091 13.1615 5.98091C12.9111 5.98091 12.6983 6.07103 12.5231 6.25128L9.99209 8.76724L7.46862 6.25128C7.29338 6.08104 7.08059 5.99593 6.83025 5.99593C6.5799 5.99593 6.36961 6.08104 6.19938 6.25128C6.02915 6.4165 5.94403 6.62429 5.94403 6.87463C5.94403 7.11496 6.03165 7.32275 6.20689 7.49799L8.73036 10.0215L6.20689 12.5374C6.03165 12.7177 5.94403 12.9255 5.94403 13.1608C5.94403 13.4161 6.02915 13.6314 6.19938 13.8067C6.36961 13.9769 6.5799 14.062 6.83025 14.062Z'
        fill='#EA4444'
      />
    </svg>
  )
}

export default React.memo(Rejected)
