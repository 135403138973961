import React, { useCallback, useMemo, FC } from 'react'
import { useSelector } from 'react-redux'
import { useAlert } from '@blaumaus/react-alert'

import { DropdownMenuItemOptions, DropdownMenuOptionType } from '@/components/blocks/DropdownMenuList'
import { ACTIONS_BY_MODEL_KEY } from '@/helpers/model/table/actions'
import { getConfigByPathname, useCardsHelper } from '@/hooks/useCardsHelper'
import { useCognitoUser } from '@/hooks/useCognitoUser'

import { useAppDispatch } from '@/redux/store'
import { PageConfig } from '@/redux/actions/common/ui'
import { requestRunActionAction } from '@/redux/actions/actions'
import { selectForm } from '@/redux/selectors/form'
import { Text } from '@/components/typography/Text'
import { getHeaderActionIcons } from '../../../../utils'
import { ActionItem, Wrapper } from './styles'

type ActionItem = {
  title: string
  type?: DropdownMenuOptionType
  options?: DropdownMenuItemOptions[]
  onClick?: (value?: string) => void
  icon?: string
}

type InlineActionsProps = {
  disableActions: boolean
  page: PageConfig
}

const InlineActions: FC<InlineActionsProps> = ({ disableActions, page }) => {
  const alert = useAlert()
  const dispatch = useAppDispatch()
  const { hasWriteAccess } = useCognitoUser()

  const { modelKey, id } = useMemo(() => getConfigByPathname(page.path), [page.path])
  const { data } = useSelector(selectForm(id!))

  const handleRunAction = useCallback(
    (action?: string) => async () => {
      try {
        if (!action || !id) return
        await dispatch(requestRunActionAction({ modelKey, action, ids: [id] })).unwrap()
        alert.show('Action started')
      } catch (e) {
        alert.error((e as any).message)
      }
    },
    [dispatch, modelKey, id, alert]
  )

  const actionOptions = useMemo(() => {
    const actions = ACTIONS_BY_MODEL_KEY[modelKey]
    if (!actions || actions.length === 0 || id === 'new') {
      return []
    }
    const allowedActions = actions.filter(({ filter }) => {
      if (!filter) {
        return true
      }
      const fields = Object.keys(filter)
      return fields.every((field) => filter[field].includes(data[field]))
    })
    const mappedActions = allowedActions
      .filter((item) => item.rootMenu)
      .map(({ action, type, icon, rootMenu }) => {
        return {
          type,
          title: action.split('_').join(' '),
          onClick: handleRunAction(action),
          icon
        } as ActionItem
      })
    return mappedActions
  }, [modelKey, id, data, handleRunAction])

  const items: ActionItem[] = useMemo(
    () => [...(hasWriteAccess && !disableActions && actionOptions.length > 0 ? actionOptions : [])],
    [hasWriteAccess, disableActions, actionOptions, id]
  )

  return (
    <Wrapper>
      {items.map((item) => (
        <ActionItem onClick={() => item.onClick?.()}>
          {getHeaderActionIcons(item.icon)}
          <Text size='sm' weight='medium' $color='#51616C'>
            {item.title}
          </Text>
        </ActionItem>
      ))}
    </Wrapper>
  )
}

export default InlineActions
