import React, { FC } from 'react'
import OpenIcon from '@/components/icons/bundle/open'
import PdfIcon from '@/components/icons/bundle/pdf'
import { FileInputComponent, FileInputWrapper, OpenIconContainer, PdfIconContainer, TextContainer } from './styles'

interface FileFieldProps {
  content: string
}

export const FileField: FC<FileFieldProps> = ({ content }) => {
  const handleIconClick = () => {
    const fileWindow = window.open('')
    fileWindow?.document.write(`<iframe width='100%' height='100%' src='${content}'></iframe>`)
  }

  return (
    <FileInputWrapper>
      <FileInputComponent>
        <TextContainer>
          <PdfIconContainer>
            <PdfIcon />
          </PdfIconContainer>
          Document.pdf
        </TextContainer>
        <OpenIconContainer onClick={handleIconClick}>
          <OpenIcon />
        </OpenIconContainer>
      </FileInputComponent>
    </FileInputWrapper>
  )
}
