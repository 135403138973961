import React, { useMemo } from 'react'
import { Outlet } from 'react-router-dom'

import SidebarMenu from '@/components/regions/SidebarMenu'
import { useCardsHelper } from '@/hooks/useCardsHelper'
import { PageContent, PageLayout } from './styles'

const Page: React.FC = () => {
  const { activeCards } = useCardsHelper()

  const isMenuHidden = useMemo(
    () => activeCards[1]?.page?.isFullWidthWhenExpanded && activeCards[1]?.page?.expanded,
    [activeCards[1]?.page?.isFullWidthWhenExpanded, activeCards[1]?.page?.expanded]
  )

  return (
    <PageLayout>
      <SidebarMenu />
      <PageContent $isMenuHidden={isMenuHidden}>
        <Outlet />
      </PageContent>
    </PageLayout>
  )
}

export default Page
