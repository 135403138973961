import React, { useCallback } from 'react'

import JsonEditorField from '@/components/fields/JsonEditorField'
import RichTextField from '@/components/fields/RichTextField'
import RelationSelectField from '@/components/fields/RelationSelectField'
import EnumSelectField from '@/components/fields/EnumSelectField'
import IndexViewField from '@/components/fields/IndexViewField'
import ImageSelectField from '@/components/fields/ImageSelectField'
import ObjectField from '@/components/fields/ObjectField'
import ClassField from '@/components/fields/ClassField'
import BundleField from '@/components/fields/BundleField'

import { FieldConfig } from '@/helpers/model/form/fields'
import { getIndexFieldsByModelKey } from '@/helpers/model/form/indexFields'
import { FilterOption } from '@/helpers/model/table/filterAndSort'
import { PageConfig } from '@/redux/actions/common/ui'
import { ModelType } from '@/client/schema'
import InputFieldArray from '@/components/fields/InputFieldArray'

type Props = {
  modelKey: string
  createMode?: boolean
  hasWriteAccess?: boolean
  config: FieldConfig
  parentPage?: PageConfig

  onOpenForm?: (formModelKey: string, id: string, expanded: boolean) => void
  onOpenIndexTable?: (indexModelKey: string, filter: FilterOption) => void
}
const LargeFields: React.FC<Props> = ({
  modelKey,
  createMode,
  hasWriteAccess,
  config,
  parentPage,

  onOpenForm,
  onOpenIndexTable
}) => {
  const handleOpenIndexTable = useCallback(
    (indexModelKey: string, filter: FilterOption) => () => {
      onOpenIndexTable && onOpenIndexTable(indexModelKey, filter)
    },
    [onOpenIndexTable]
  )
  const handleOpenForm = useCallback(
    (formModelKey: string) => (id: string, expanded: boolean) => onOpenForm && onOpenForm(formModelKey, id, expanded),
    [onOpenForm]
  )

  if (!config) {
    return null
  }

  const label = config.hideLabel ? undefined : config.label || config.name.toUpperCase()
  const disabled = !hasWriteAccess || config.disabled?.all || (config.disabled?.edit && !createMode)
  if (config.type === 'enum-select') {
    return <EnumSelectField name={config.name} options={config.options} disabled={disabled} label={label} />
  }
  if (config.type === 'relation') {
    return (
      <RelationSelectField
        name={config.name}
        modelKey={config.modelKey}
        state={config.state}
        disabled={disabled}
        label={label}
        previewFields={config.previewFields}
        onOpen={handleOpenForm(config.modelKey!)}
      />
    )
  }
  if (config.type === 'object') {
    return (
      <ObjectField
        name={config.name}
        modelKey={config.modelKey}
        state={config.state}
        disabled={disabled}
        label={label}
        previewFields={config.previewFields}
        models={config.refs!}
        list={config.list}
        parentPage={parentPage!}
      />
    )
  }
  if (config.type === 'class') {
    return (
      <ClassField
        name={config.name}
        modelKey={config.modelKey}
        state={config.state}
        disabled={disabled}
        label={label}
        previewFields={config.previewFields}
        model={config.ref! as ModelType}
        list={config.list}
        parentPage={parentPage!}
      />
    )
  }
  if (config.type === 'index') {
    const indexFields = getIndexFieldsByModelKey(modelKey)

    return (
      <IndexViewField
        name={config.name}
        modelKey={config.modelKey}
        state={config.state}
        label={label}
        previewFields={config.previewFields}
        onOpen={handleOpenForm(config.modelKey!)}
        onOpenIndexTable={handleOpenIndexTable(config.modelKey!, indexFields[config.name])}
      />
    )
  }
  if (config.type === 'image') {
    return (
      <ImageSelectField
        name={config.name}
        modalPath={config.modalPath!}
        path={config.path!}
        extension={config.extension!}
        size={config.size!}
        list={config.list}
        disabled={disabled}
        label={label}
        pathWithoutSize={config.pathWithoutSize}
      />
    )
  }
  if (config.type === 'json') {
    return <JsonEditorField name={config.name} label={label} disabled={disabled} />
  }
  if (config.type === 'rich-text') {
    return (
      <RichTextField
        name={config.name}
        modalPath={config.imagesModalPath!}
        path={config.imagesPath!}
        extension={config.imagesExtension!}
        size={config.size!}
        label={label}
        disabled={disabled}
      />
    )
  }
  if (config.type === 'input' && config.list === true) {
    return (
      <InputFieldArray
        name={config.name}
        type={config.inputType}
        label={label}
        disabled={disabled}
        placeholder={`Enter ${config.inputType || 'value'}...`}
      />
    )
  }

  if (config.type === 'bundle') {
    return <BundleField name={config.name} path={config.path!} extension={config.extension!} />
  }

  return null
}

LargeFields.defaultProps = {
  createMode: undefined,
  hasWriteAccess: undefined,
  parentPage: undefined,

  onOpenForm: undefined,
  onOpenIndexTable: undefined
}

export default LargeFields
