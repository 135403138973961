import { createAction } from '@reduxjs/toolkit'

import { CardType } from '@/hooks/useCardsHelper'
import { ModalType } from '@/modals/types'

export const UI_ACTION = 'UI'

export const OPEN_PAGE_ACTION = `${UI_ACTION}/OPEN_PAGE`
export const CLOSE_PAGE_ACTION = `${UI_ACTION}/CLOSE_PAGE`
export const EXPAND_PAGE_ACTION = `${UI_ACTION}/EXPAND_PAGE`
export const RESET_PAGES_ACTION = `${UI_ACTION}/RESET_PAGES`
export const MODAL_ACTION = `${UI_ACTION}/MODAL`

export type PageConfig = {
    type: CardType
    path: string
    expanded: boolean
    afterPage?: string
    isFullWidthWhenExpanded?: boolean
}
export const setOpenPageAction = createAction<PageConfig>(OPEN_PAGE_ACTION)
export const resetPagesAction = createAction<PageConfig[]>(RESET_PAGES_ACTION)

export type ExpandPage = { path: string; expanded: boolean }
export const setExpandPageAction = createAction<ExpandPage>(EXPAND_PAGE_ACTION)

export type ModalAction = { type: ModalType; data: any } | undefined
export const setModalAction = createAction<ModalAction>(MODAL_ACTION)
