export const PRIMARY = {
    base: {
        white: '#ffffff',
        black: '#000000'
    },
    gray: {
        25: '#FCFCFD',
        50: '#F9FAFB',
        100: '#F2F4F7',
        200: '#EAECF0',
        300: '#D0D5DD',
        400: '#98A2B3',
        500: '#667085',
        600: '#475467',
        700: '#344054',
        800: '#1D2939',
        900: '#101828'
    },
    primary: {
        25: '#F5F8FF',
        50: '#EFF4FF',
        100: '#D1E0FF',
        200: '#B2CCFF',
        300: '#84ADFF',
        400: '#528BFF',
        500: '#2970FF',
        600: '#155EEF',
        700: '#004EEB',
        800: '#0040C1',
        900: '#00359E'
    },
    error: {
        25: '#FFFBFA',
        50: '#FEF3F2',
        100: '#FEE4E2',
        200: '#FECDCA',
        300: '#FDA29B',
        400: '#F97066',
        500: '#F04438',
        600: '#D92D20',
        700: '#B42318',
        800: '#912018',
        900: '#7A271A'
    },
    warning: {
        25: '#FFFCF5',
        50: '#FFFAEB',
        100: '#FEF0C7',
        200: '#FEDF89',
        300: '#FEC84B',
        400: '#FDB022',
        500: '#F79009',
        600: '#DC6803',
        700: '#B54708',
        800: '#93370D',
        900: '#7A2E0E'
    },
    success: {
        25: '#F6FEF9',
        50: '#ECFDF3',
        100: '#D1FADF',
        200: '#A6F4C5',
        300: '#6CE9A6',
        400: '#32D583',
        500: '#12B76A',
        600: '#039855',
        700: '#027A48',
        800: '#05603A',
        900: '#054F31'
    }
}

export const SECONDARY = {
    moss: {
        25: '#FAFDF7',
        50: '#F5FBEE',
        100: '#E6F4D7',
        200: '#CEEAB0',
        300: '#ACDC79',
        400: '#86CB3C',
        500: '#669F2A',
        600: '#4F7A21',
        700: '#3F621A',
        800: '#335015',
        900: '#2B4212'
    },
    violet: {
        25: '#FBFAFF',
        50: '#F5F3FF',
        100: '#ECE9FE',
        200: '#DDD6FE',
        300: '#C3B5FD',
        400: '#A48AFB',
        500: '#875BF7',
        600: '#7839EE',
        700: '#6927DA',
        800: '#5720B7',
        900: '#491C96'
    },
    cyan: {
        25: '#F5FEFF',
        50: '#ECFDFF',
        100: '#CFF9FE',
        200: '#A5F0FC',
        300: '#67E3F9',
        400: '#22CCEE',
        500: '#06AED4',
        600: '#088AB2',
        700: '#0E7090',
        800: '#155B75',
        900: '#164C63'
    }
}

export const BUNDLE = {
    bundle: {
        background: '#F6F7F7',
        border: '#F2F2F2',
        label: '#54616B',
        text: '#2C2F31'
    }
}
