import React, { FC } from 'react'
import { useTheme } from 'styled-components'
import { Text } from '@/components/typography/Text'
import { Status } from '../../types'
import { BaseFieldContainer, InputWrapper, StyledInput } from './styles'
import { FileField } from '../FileField'
import { StatusIcon } from '../StatusIcon'

interface BaseFieldProps {
  label: string
  value: string
  status?: Status
  type?: string
}

export const BaseField: FC<BaseFieldProps> = ({ label, value, status, type }) => {
  const theme = useTheme()
  const isFileType = type === 'FILE'

  return (
    <BaseFieldContainer>
      <Text size='sm' $color={theme.colors.bundle.label}>
        {label}
      </Text>
      {isFileType ? (
        <FileField content={value} />
      ) : (
        <InputWrapper>
          <StyledInput value={value} readOnly />
          {status && <StatusIcon status={status} />}
        </InputWrapper>
      )}
    </BaseFieldContainer>
  )
}
