import 'styled-components'

import { PRIMARY, SECONDARY, BUNDLE } from './colors'
import { FLUFFY, REGULAR, TIGHT } from './shadows'
import { TEXT_SIZE_STYLES, TEXT_WEIGHT_STYLES } from './typography/text'
import { DISPLAY_SIZE_STYLES, DISPLAY_WEIGHT_STYLES } from './typography/display'

const theme = {
    colors: { ...PRIMARY, ...SECONDARY, ...BUNDLE },
    shadows: {
        regular: REGULAR,
        fluffy: FLUFFY,
        tight: TIGHT
    },
    typography: {
        text: {
            size: TEXT_SIZE_STYLES,
            weight: TEXT_WEIGHT_STYLES
        },
        display: {
            size: DISPLAY_SIZE_STYLES,
            weight: DISPLAY_WEIGHT_STYLES
        }
    }
}

export type Theme = typeof theme
export default theme

declare module 'styled-components' {
    // eslint-disable-next-line @typescript-eslint/no-empty-interface
    export interface DefaultTheme extends Theme {}
}
