/* eslint-disable max-len */
import * as React from 'react'

function Approve(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M11.04 6.5925L7.8225 9.8175L6.585 8.58C6.51777 8.50149 6.43503 8.43772 6.34198 8.39271C6.24893 8.34769 6.14758 8.32239 6.04429 8.3184C5.941 8.31441 5.838 8.33182 5.74176 8.36952C5.64552 8.40723 5.55811 8.46442 5.48502 8.53751C5.41192 8.6106 5.35473 8.69801 5.31703 8.79426C5.27932 8.8905 5.26191 8.9935 5.2659 9.09679C5.26989 9.20008 5.29519 9.30143 5.34021 9.39448C5.38523 9.48753 5.44899 9.57026 5.5275 9.6375L7.29 11.4075C7.36008 11.477 7.4432 11.532 7.53457 11.5693C7.62595 11.6067 7.7238 11.6256 7.8225 11.625C8.01926 11.6242 8.20781 11.5461 8.3475 11.4075L12.0975 7.6575C12.1678 7.58778 12.2236 7.50483 12.2617 7.41343C12.2997 7.32204 12.3194 7.22401 12.3194 7.125C12.3194 7.02599 12.2997 6.92796 12.2617 6.83657C12.2236 6.74517 12.1678 6.66222 12.0975 6.5925C11.957 6.45281 11.7669 6.37441 11.5688 6.37441C11.3706 6.37441 11.1805 6.45281 11.04 6.5925ZM9 1.5C7.51664 1.5 6.0666 1.93987 4.83323 2.76398C3.59986 3.58809 2.63856 4.75943 2.07091 6.12987C1.50325 7.50032 1.35472 9.00832 1.64411 10.4632C1.9335 11.918 2.64781 13.2544 3.6967 14.3033C4.7456 15.3522 6.08197 16.0665 7.53683 16.3559C8.99168 16.6453 10.4997 16.4968 11.8701 15.9291C13.2406 15.3614 14.4119 14.4001 15.236 13.1668C16.0601 11.9334 16.5 10.4834 16.5 9C16.5 8.01509 16.306 7.03982 15.9291 6.12987C15.5522 5.21993 14.9997 4.39314 14.3033 3.6967C13.6069 3.00026 12.7801 2.44781 11.8701 2.0709C10.9602 1.69399 9.98492 1.5 9 1.5ZM9 15C7.81332 15 6.65328 14.6481 5.66658 13.9888C4.67989 13.3295 3.91085 12.3925 3.45673 11.2961C3.0026 10.1997 2.88378 8.99334 3.11529 7.82946C3.3468 6.66557 3.91825 5.59647 4.75736 4.75736C5.59648 3.91824 6.66558 3.3468 7.82946 3.11529C8.99335 2.88378 10.1997 3.0026 11.2961 3.45672C12.3925 3.91085 13.3295 4.67988 13.9888 5.66658C14.6481 6.65327 15 7.81331 15 9C15 10.5913 14.3679 12.1174 13.2426 13.2426C12.1174 14.3679 10.5913 15 9 15Z'
        fill='#51616C'
      />
    </svg>
  )
}

export default React.memo(Approve)
