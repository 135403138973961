import React, { useCallback, useMemo } from 'react'
import { useAlert } from '@blaumaus/react-alert'
import { useSelector } from 'react-redux'

import DropdownMenuList, { DropdownMenuOption } from '@/components/blocks/DropdownMenuList'
import { ACTIONS_BY_MODEL_KEY, ACTIONS_VISIBILITY_BY_MODEL_KEY } from '@/helpers/model/table/actions'
import { useCardsHelper } from '@/hooks/useCardsHelper'

import { useAppDispatch } from '@/redux/store'
import { requestDeleteRowsAction } from '@/redux/actions/table'
import { selectSelectedRows, selectTable } from '@/redux/selectors/table'
import { requestRunActionAction } from '@/redux/actions/actions'

type Props = { modelKey: string; onCloseMenu: () => void }
const TableActions: React.FC<Props> = ({ modelKey, onCloseMenu }) => {
  const alert = useAlert()
  const dispatch = useAppDispatch()
  const { onCloseFormsById } = useCardsHelper()

  const { data } = useSelector(selectTable(modelKey))
  const selectedRows = useSelector(selectSelectedRows(modelKey))
  const { delete: deleteVisibility } = useMemo(() => ACTIONS_VISIBILITY_BY_MODEL_KEY[modelKey], [modelKey])

  const handleRunAction = useCallback(
    (action?: string) => async () => {
      try {
        if (!action) return
        onCloseMenu()
        alert.show('Action started')
        await dispatch(requestRunActionAction({ modelKey, action, ids: selectedRows })).unwrap()
      } catch (e) {
        alert.error((e as any).message)
      }
    },
    [modelKey, selectedRows, alert, dispatch, onCloseMenu]
  )
  const handleDelete = useCallback(() => {
    dispatch(requestDeleteRowsAction({ modelKey }))
    onCloseMenu()
    onCloseFormsById(selectedRows)
    alert.show('Deleted')
  }, [modelKey, selectedRows, alert, dispatch, onCloseMenu, onCloseFormsById])

  const actionOptions = useMemo(() => {
    const actions = ACTIONS_BY_MODEL_KEY[modelKey]
    if (!actions || actions.length === 0) {
      return []
    }
    const rows = data.filter(({ id }) => selectedRows.includes(id))
    const allowedActions = actions.filter(({ filter }) => {
      if (!filter) {
        return true
      }
      const fields = Object.keys(filter)
      return rows.every((row) => fields.every((field) => filter[field].includes(row[field])))
    })
    const groupActions = allowedActions.reduce(
      (accumulator, { action, group, type }) => {
        const actionConfig = {
          type,
          title: action.split('_').join(' '),
          onClick: handleRunAction(action)
        } as DropdownMenuOption
        if (group) {
          return {
            ...accumulator,
            [group]: [...(accumulator[group] || []), actionConfig]
          }
        }
        return {
          ...accumulator,
          default: [...(accumulator.default || []), actionConfig]
        }
      },
      {} as Record<string, DropdownMenuOption[]>
    )
    return Object.keys(groupActions).map((key) => groupActions[key])
  }, [modelKey, data, selectedRows, handleRunAction])

  const items: DropdownMenuOption[][] = useMemo(
    () => [
      ...(actionOptions.length > 0 ? actionOptions : []),
      deleteVisibility ? [{ title: 'Delete', type: 'delete', onClick: handleDelete }] : []
    ],
    [actionOptions, handleDelete, deleteVisibility]
  )

  return <DropdownMenuList items={items} />
}

export default TableActions
